import React, { useEffect, useState } from "react";
import { Logo, LoginImage } from "../../constant/images";
import { Button, Form, Input, message } from 'antd';
import { Link, useNavigate } from "react-router-dom";
// import { SocialButton } from "../../components/buttons/buttons";
// import { Google, Apple, Facebook } from "../../constant/icons";
import { useGetBannerQuery, useGetSettingQuery, useUserLoginMutation } from "../../store/loginRegisterSlice";
import { useDispatch } from "react-redux";
import { allBanner, dynamicWebData, loginSuccess } from "../../store/authSlice";

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userLogin, { isLoading }] = useUserLoginMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const [isSaving , setIsSaving] = useState(false)
    const[dynamicData,setDynamicData] = useState()
    const{data:getSetting} = useGetSettingQuery()
    const{data:getBanner} = useGetBannerQuery()
    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const onFinish = async (values) => {
        setIsSaving(true)
        try {
            const res = await userLogin(values);
            if (res.data) {
                localStorage.setItem("Token", res.data.token)
                success('Login successful')
                navigate('/homepage')
                dispatch(loginSuccess({ user: res.data }))
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred');
        }finally{
            setIsSaving(false)
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(()=>{
        setDynamicData(getSetting?.setting)
        dispatch(dynamicWebData(getSetting?.setting))
        dispatch(allBanner(getBanner?.banner))
    },[getSetting,getBanner])


    return (
        <div className="authWrapper">
            <div className="innerWrapper">
                <div className="container">
                    {contextHolder}
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="card authCard">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6 d-flex align-items-center">
                                            <div className="formWrapper">
                                                <div className="logoSection">
                                                    <img src={dynamicData?.logo?`https://rainfrog-api.aleaspeaks.com/storage/${dynamicData?.logo}`:Logo} className="img-fluid" alt="logo" />
                                                </div>
                                                <div className="formSection">
                                                    <h4 className="authTitle">Sign In</h4>
                                                    <p className="subTitle">Welcome! Please enter your details.</p>
                                                    <div className="fomrWrap">
                                                        <Form
                                                            name="loginForm"
                                                            layout="vertical"
                                                            onFinish={onFinish}
                                                            onFinishFailed={onFinishFailed}
                                                            autoComplete="off"
                                                        >
                                                            <Form.Item
                                                                name="email"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input your email!',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input className="form-control" placeholder="Email"  autoComplete="off"/>
                                                            </Form.Item>

                                                            <Form.Item
                                                                name="password"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input your password!',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input.Password className="form-control" placeholder="Password" autoComplete="off"/>
                                                            </Form.Item>

                                                            <Form.Item className="forgotSection">
                                                                <Link to={"/forgot-password"} className="forgotLink">Forgot Password?</Link>
                                                            </Form.Item>

                                                            <Form.Item>
                                                                <Button type="primary" htmlType="submit" className="primaryButton" disabled={isSaving} >
                                                                    {isLoading ? "Loading..." : "Sign In"}
                                                                </Button>
                                                            </Form.Item>
                                                        </Form>
                                                    </div>
                                                    {/* <div className="signUpRow">
                                                        <p>Don’t have an account? 
                                                            <Link to={"/signup"}> Sign Up</Link>
                                                        </p>
                                                    </div> */}
                                                    {/* <div className="orSection">
                                                        <p className="orText">Or</p>
                                                    </div> */}
                                                    {/* <div className="socialLoginSection">
                                                        <SocialButton
                                                            className='socialBtn'
                                                            text="Continue with Google"
                                                            icon={<Google />}
                                                            onClick={() => console.log("Clicked")}
                                                        />

                                                        <SocialButton
                                                            className='socialBtn'
                                                            text="Continue with Apple"
                                                            icon={<Apple />}
                                                            onClick={() => console.log("Clicked")}
                                                        />

                                                        <SocialButton
                                                            className='socialBtn'
                                                            text="Continue with Facebook"
                                                            icon={<Facebook />}
                                                            onClick={() => console.log("Clicked")}
                                                        />
                                                    </div> */}
                                                    <div className="bottomText mt-5">
                                                        <p>
                                                            By signing in, you agree to our <Link to={'/terms-and-conditions'} className="linkText">Terms of Service</Link> and acknowledge that you have read our <Link to={'/privacy-policy'} className="linkText">Privacy Policy</Link>.
                                                        </p>
                                                        <p>
                                                           If you facing any issue <Link to={'/contact-us'} className="linkText">Contact Us</Link>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mobHide">
                                            <div className="rightSection">
                                                <img src={LoginImage} className="img-fluid" alt="login" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
