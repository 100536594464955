import React, { useState, useEffect } from "react";
import { Survey, Model as SurveyModel } from 'survey-react';
import "survey-core/defaultV2.css";
import FeaturedSection from "../../components/features/features";
import BannerSection from "./banner";
import CTA from "../../components/cta/cta";
import "./survey.scss";
import { Modal } from 'antd';
import axios from "axios";

const Homepage = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [surveyModel, setSurveyModel] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => setIsPopupVisible(true), 1000); // 1-second delay
        return () => clearTimeout(timer); // Clean up timer on unmount
    }, []);

    useEffect(() => {
        axios.get(`https://sunbird-staging.aleaspeaks.com/api/v1/convert-to-surveyJs?flow_id=165`)
            .then(response => {
                const model = new SurveyModel(response?.data);
                model.onValueChanged.add(handleValueChanged);
                setSurveyModel(model);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleValueChanged = (sender, options) => {
        console.log("Question name:", options.name);
        console.log("New value:", options.value);
        // Additional logic can be added here
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <div className="homepageWrapper">
            <BannerSection />
            <FeaturedSection />
            <CTA />

            <Modal
                className="phone-modal"
                open={isPopupVisible}
                onCancel={handleClosePopup}
                footer={null}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                style={{ bottom: 0, right: 0, padding: 0 }}
            >
                <div className="popup-content">
                    {surveyModel && (
                        <Survey
                            model={surveyModel}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default Homepage;
