import React, { useState } from "react";
import FeaturedSection from "../../components/features/features";
import Gallery from "./gallery";
import { Anchor, Spin } from "antd";
// import LocalInfo from "./localInfo";
import { FaLocationDot } from "react-icons/fa6";
// import RealEstate from "./realEstate";
import Survey from "../SurveyJS/SurveyComponent";
import { Link, useLocation } from "react-router-dom";
import PropertyInformation from "./propertyInformation";
import CalculatePayment from "./calculatePayment";
import { useGetPropertyDetailsQuery } from "../../store/getSlice";

const PropertyDetails = () => {
    const location = useLocation()
    const { data: getPropertyDetails, isLoading } = useGetPropertyDetailsQuery(location?.state);
    const [propertyType, setPropertyType] = useState();

    return (
        <div className="propertyDetailsWrapper">
            <Gallery />
            <div className="container">
                <div className="detailsSection">
                    <div className="anchors">
                        <Anchor
                            direction="horizontal"
                            items={[
                                {
                                    key: 'details',
                                    href: '#details',
                                    title: 'Property Details',
                                },
                                {
                                    key: 'contact',
                                    href: '#contact',
                                    title: 'Contact Info',
                                },
                                {
                                    key: 'price',
                                    href: '#price',
                                    title: 'Price Info',
                                },
                                {
                                    key: 'payment',
                                    href: '#payment',
                                    title: 'Calculate Payment',
                                },
                                {
                                    key: 'realEstate',
                                    href: '#realEstate',
                                    title: 'Real Estate',
                                },
                            ]}
                        />
                    </div>
                    {isLoading ? <Spin style={{ display: "flex", alignItems: "center", justifyContent: "center" }} size="large" /> :
                        <div className="card detailsCard">
                            <div className="card-body p-0">
                                <div className="topInformation">
                                    <div className="inormation">
                                        <h4 className="name">{getPropertyDetails?.data?.unparsed_address ?? 'No data found'}</h4>
                                        <p className="location"><FaLocationDot />{getPropertyDetails?.data?.postal_city}, {getPropertyDetails?.data?.state_or_province},{getPropertyDetails?.data?.country}-{getPropertyDetails?.data?.postal_code}</p>
                                        <div className="bottomDetails">
                                            <span className="dtl">{getPropertyDetails?.data?.property_type ?? 'No data found'}</span>
                                            <span className="divider"></span>
                                            <span className="dtl">{getPropertyDetails?.data?.property_subtype ?? 'No data found'}</span>
                                        </div>
                                    </div>
                                    <div className="price">
                                        <h4>
                                            {getPropertyDetails?.data?.price
                                                ? getPropertyDetails?.data?.price.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                })
                                                : '$0.00'}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header" id="details">
                                <h4 className="card-title">Property Details</h4>
                            </div>
                            <div className="card-body">
                                <PropertyInformation details={getPropertyDetails?.data} />
                            </div>
                            <div className="card-header" id="contact">
                                <h4 className="card-title">Contact Info</h4>
                            </div>
                            <div className="card-body">
                                <div className="agentInfo">
                                    <h4 className="agentName">Agent (S)</h4>
                                    <p className="agentDetails">{getPropertyDetails?.data?.agent_full_name ?? 'No data found'}</p>
                                    <p className="agentDetails">{getPropertyDetails?.data?.agent_email ?? 'No data found'}</p>
                                    <p className="agentDetails">Website: {getPropertyDetails?.data?.listing_url ?? 'No data found'}</p>
                                    <p className="phone">
                                        <Link to={"tel:888-773-4547"}>{getPropertyDetails?.data?.agent_office_phone ?? 'No data found'}</Link>
                                    </p>
                                    <p className="bottomText">
                                        Can’t Find a contact or is there a problem with this listing? <Link to={"/contact-us"} className="link">Contact Us</Link>
                                    </p>
                                </div>
                            </div>

                            <div className="card-header" id="price">
                                <h4 className="card-title">Price Information</h4>
                            </div>

                            <div className="card-body">
                                <div className="priceWrapper">
                                    <p className="subText">
                                        {getPropertyDetails?.data?.price
                                            ? getPropertyDetails?.data?.price.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })
                                            : '$0.00'}
                                    </p>
                                </div>
                            </div>

                            <div className="card-header" id="payment">
                                <h4 className="card-title">Calculate Payment</h4>
                            </div>
                            <div className="card-body">
                                <CalculatePayment amount={getPropertyDetails?.data?.price} type={propertyType} />
                            </div>
                            <div className="card-header" id="realEstate">
                                <h4 className="card-title">Real Estate Professional In Your Area</h4>
                            </div>

                            <div className="card-body">
                                <Survey />
                            </div>

                        </div>
                    }
                </div>
            </div>
            <FeaturedSection className="bgGrey mb-0" />
        </div>
    );
};

export default PropertyDetails;