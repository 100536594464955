import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CTA from "../../components/cta/cta";

const MortgageCalculator = () => {
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [mortgagecalculation, setMortgageCalculation] = useState(null);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [downPayment, setDownPayment] = useState(0);
    const [downPaymentPercent, setDownPaymentPercent] = useState(0);

    const calculateDownPaymentPercent = (dp, price) => {
        if (price && dp) {
            const percent = ((dp / price) * 100).toFixed(2);
            setDownPaymentPercent(percent);
        } else {
            setDownPaymentPercent(0);
        }
    };

    const onPriceChange = (e) => {
        const price = parseFloat(e.target.value);
        setPurchasePrice(price);
    };

    const onDownPaymentChange = (e) => {
        const dp = parseFloat(e.target.value);
        setDownPayment(dp);
        calculateDownPaymentPercent(dp, purchasePrice);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const calculateMortgage = (values) => {
        const purchasePrice = parseFloat(values.price);
        const downPayment = parseFloat(values.dp);
        const loanAmount = purchasePrice - downPayment;
        const loanTerm = parseInt(values.loan_type);
        const monthlyInterestRate = values.annualInterestRate / 100 / 12;
        const numberOfPayments = loanTerm * 12;

        // Mortgage calculation formula
        const monthlyPayment =
            (loanAmount *
                monthlyInterestRate *
                Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

        const totalPayableInterest = (monthlyPayment * numberOfPayments) - loanAmount;
        const firstMonthInterest = loanAmount * monthlyInterestRate;
        const firstMonthPrincipal = monthlyPayment - firstMonthInterest;

        setMonthlyPayment({
            total: monthlyPayment.toFixed(2),
            principal: firstMonthPrincipal.toFixed(2),
            interest: firstMonthInterest.toFixed(2),
        });

        setMortgageCalculation({
            total: (monthlyPayment * numberOfPayments).toFixed(2),
            principal: loanAmount.toFixed(2),
            interest: totalPayableInterest.toFixed(2),
        })
    };

    const onFinish = (values) => {
        calculateMortgage(values);
    };

    const chartOptions = {
        chart: {
            type: "pie",
        },

        title: {
            text: "Principal vs Interest Breakdown",
        },

        plotOptions: {
            pie: {
                innerSize: "70%",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: ${point.y:.1f}",
                },
            },
        },

        series: [
            {
                name: "Payment Breakdown",
                data: [
                    {
                        name: "Principal",
                        y: mortgagecalculation ? (parseFloat(mortgagecalculation.principal)) : 0,
                    },

                    {
                        name: "Interest",
                        y: mortgagecalculation ? parseFloat(mortgagecalculation.interest) : 0,
                    },
                ],
            },
        ],
    };

    return (
        <div className="propertyDetailsWrapper mb-5 mortgageWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleSection">
                            <h5 className="topTitle">Mortgage</h5>
                            <h3 className="mainTitle">Mortgage Calculator</h3>
                        </div>
                    </div>
                </div>
                <div className="detailsSection mt-0">
                    <div className="card detailsCard mt-0">
                        <div className="card-header bg-transparent border-0 border-bottom">
                            <h5 className="card-title mb-3">Mortgage Calculator</h5>
                            <p className="subText mb-0">
                                For traditional mortgage, enter your home loan details and get
                                personalized quotes with today’s mortgage rates.
                            </p>
                        </div>
                        <div className="card-body mt-4">
                            <div className="calculateWrapper">
                                <div className="innerWrap">
                                    <div className="formWrapper">
                                        <Form
                                            name="PaymentForm"
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout="vertical"
                                        >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Form.Item
                                                        label="Purchase Price"
                                                        name="price"
                                                        rules={[
                                                            {
                                                                required: true,

                                                                message: "Please input your purchase price",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="$ 205,900" className="form-control" onChange={onPriceChange} />
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <Form.Item
                                                        label="Down Payment"
                                                        name="dp"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your down payment",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="$ 205,900"
                                                            className="form-control-addon"
                                                            addonAfter={`${downPaymentPercent}%`}
                                                            onChange={onDownPaymentChange}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <Form.Item
                                                        label="Loan Tenure"
                                                        name="loan_type"
                                                        rules={[
                                                            {
                                                                required: true,

                                                                message: "Select the loan tenure",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="form-control-check"
                                                            onChange={handleChange}
                                                            placeholder="Select loan tenure"
                                                        >
                                                            <Select.Option value="10">10 Year Fixed</Select.Option>
                                                            <Select.Option value="15">15 Year Fixed</Select.Option>
                                                            <Select.Option value="20">20 Year Fixed</Select.Option>
                                                            <Select.Option value="30">30 Year Fixed</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <Form.Item
                                                        label="Annual Interest Rate"
                                                        name="annualInterestRate"
                                                        rules={[
                                                            {
                                                                required: true,

                                                                message: "Please input your annual interest rate",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="e.g. 5.0" className="form-control" />
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-12 text-center my-5">
                                                    <button type="submit" className="btn accountBtn w-auto">
                                                        Calculate
                                                    </button>
                                                </div>

                                                {monthlyPayment && (
                                                    <div className="result">
                                                        <p>
                                                            Estimated Monthly Payment:{" "}
                                                            <strong>${monthlyPayment.total}</strong>
                                                        </p>

                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={chartOptions}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA />
        </div>
    );
};

export default MortgageCalculator;