import React, { useEffect, useState } from "react";
import { Form, Input, message, Spin } from "antd";
import { useContactSupportMutation } from "../../store/apiSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { useSelector } from "react-redux";

const ContactForm = () => {
    const [form] = Form.useForm();
    const userData = useSelector((state) => state.persistedReducer);
    const isAuthenticated = userData.isAuthenticated;
    const [messageApi, contextHolder] = message.useMessage();
    const [addQuery] = useContactSupportMutation()
    // const { data: userDetail ,refetch,isLoading} = useGetUserDetailQuery()
    const userDetail = useSelector((state) => state?.persistedReducer?.userDetail?.userDetail);
    const [isSaving , setIsSaving] = useState(false)
    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    const onFinish = async (values) => {
        setIsSaving(true)
        try {
            const res = await addQuery(values);
            if (res.data) {
                success('Added successfully');              
                if (isAuthenticated) {                
                   
                    form.resetFields(['message']);
                } else {                  
                    form.resetFields();
                }
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage('An unexpected error occurred');
        }finally {
            setIsSaving(false)
          }
    };
    

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        if (userDetail && isAuthenticated) {
            form.setFieldsValue(userDetail);
        }
    }, [userDetail]);
    return (
        <>
        {contextHolder}      
         <Form
         form={form}
            name="ContactFormForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
        >
            <div className="row">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        { required: true, message: 'Please enter your name' },
                        {
                            pattern: /^[A-Za-z\s]+$/,
                            message: 'Name can only contain letters and spaces',
                        },
                        {
                            max: 25,
                            message: 'Name cannot exceed 25 characters',
                        },
                       ]}
                >
                    <Input placeholder="Enter Name" className="form-control" />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please enter your email' },
                        {
                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "The input is not a valid E-mail!",
                          },
                       ]}
                >
                    <Input placeholder="Email Address" className="form-control" type="email" />
                </Form.Item>
                {/* <Form.Item
                    label="Address"
                    name="address"
                >
                    <Input placeholder="Address" className="form-control" />
                </Form.Item> */}
                <Form.Item
                    label="Message"
                    name="message"
                    rules={[
                        { required: true, message: 'Please enter your message' }
                       ]}
                >
                    <Input.TextArea
                        placeholder="Type here"
                        className="form-control"
                        rows={5}
                    />
                </Form.Item>
                <div className="col-md-12 my-4">
                    <button type="submit" htmltype="submit" className="btn accountBtn w-auto" disabled={isSaving}>Submit</button>
                </div>
            </div>
        </Form>
        </>
       
    );
};

export default ContactForm