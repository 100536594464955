import React, { useEffect } from "react";
import { Avatar, Spin } from "antd";
import { IoCalendarOutline } from "react-icons/io5";
import { useGetBlogDetailsQuery } from "../../store/getSlice";
import { useLocation } from "react-router-dom";
import Moment from "react-moment";

const BlogDetails = () => {
    const location = useLocation();
    
    const { data: blogDetails, isLoading } = useGetBlogDetailsQuery(
        location?.state?.data
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {isLoading ? (
                <Spin
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    size="large"
                />
            ) : (
                <div className="card detailsCard">
                    <div className="card-body">
                        <div className="blogContent">
                            <h3 className="blogTitle">{blogDetails?.blog?.title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: blogDetails?.blog?.description,
                                }}
                            />
                        </div>

                        <div className="authorDetailsSection">
                            <div className="name">
                                <Avatar
                                    className="bannerAvatar"
                                    src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                />
                                <h4 className="authorName">{blogDetails?.blog?.author}</h4>
                            </div>
                            <div className="date">
                                <span className="icon">
                                    <IoCalendarOutline />
                                </span>
                                <Moment format="MMMM DD, YYYY">
                                    {blogDetails?.blog?.created_at}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BlogDetails;
