import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDetail_Url } from '../Helper';

const commonHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
    'Accept': 'application/json',
};

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: fetchDetail_Url }),
    endpoints: (builder) => ({
        userRegister: builder.mutation({
            query: (newUser) => ({
                url: 'user/register',
                method: 'POST',
                body: newUser,
                headers: commonHeaders,
            }),

        }),
        sunbirdUserRegister: builder.mutation({
            query: ({ data, id }) => {
               
                return {
                    url: `guest/register/${id}`,
                    method: 'PUT',
                    body: data,
                    headers: commonHeaders,
                };
            },
        }),
        userLogin: builder.mutation({
            query: (userDetails) => ({
                url: 'user/login',
                method: 'POST',
                body: userDetails,
                headers: commonHeaders,
            }),


        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: 'forgot-password',
                method: 'POST',
                body: email,
                headers: commonHeaders,
            }),


        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: 'reset-password',
                method: 'POST',
                body: data,
                headers: commonHeaders,
            }),

        }),
        verifyCode: builder.mutation({
            query: (data) => ({
                url: 'verify-code',
                method: 'POST',
                body: data,
                headers: commonHeaders,
            }), 
        }),
        getPagesDetail : builder.query({
            query: (page) => {
                if (page) {
                    return {
                        url: `pages/${page}`,
                        method: "GET"
                    };
                }               
            },
        }),
        getSetting : builder.query({
            query: () => {
                    return {
                        url: `settings`,
                        method: "GET"
                    };                      
            },
        }),
        getBanner: builder.query({
            query: () => {     
                    return{
                        url:`banner`,
                        method: "GET",
                    }                
            },
            providesTags: ["Banner"],
        }),

    }),
  
})

export const { useUserRegisterMutation, useUserLoginMutation,useForgotPasswordMutation,useResetPasswordMutation ,useVerifyCodeMutation,useSunbirdUserRegisterMutation,useGetPagesDetailQuery,useGetSettingQuery,useGetBannerQuery} = authApi

