import React from "react";
import LoginInformation from "./loginInfo";
import ZipCode from "./zipCode";

const MyProfile = () => {
    return (
        <div className="accountWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titleSection">
                                <h5 className="topTitle">My Profile</h5>
                                <h3 className="mainTitle">Profile Information</h3>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="leftSection">
                                <LoginInformation />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="rightSection">
                                <ZipCode/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;