import React, { useEffect, useState } from "react";
import { Input, Form, Select, Dropdown, Space, Row, Checkbox, message } from "antd";
import { useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { useRecentlyZipCodeMutation, useSearchPropertyMutation } from "../../store/apiSlice";
import { useGetLocationQuery } from "../../store/getSlice";
import { google_Api_Key } from "../../Helper";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { saveZipCode } from "../../store/authSlice";
import { ThreeDots } from "react-loader-spinner";
import Loader from "../../constant/loader";
import axios from "axios";

const { Option } = Select;

const propertyTypes = [
    { label: "Single Family Home", value: "single_family" },
    { label: "Townhouse/Condo", value: "townhouse_condo" },
    { label: "Multi-Family", value: "multi_family" },
    { label: "Commercial", value: "commercial" },
    { label: "Vacant Land", value: "vacant_land" },
    { label: "Mobile/Manufactured", value: "mobile_manufactured" },

];

const DropdownContent = ({ setFilter }) => {
    const [form] = Form.useForm();
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(null);

    const onFinish = async (values) => {
        let value = {
            square_feet: values?.square_feet,
            year_built: values?.year_built,
            property_type: values?.property_type,
        };

        setFilter(value);
    };

    const handleCheckboxChange = (label, checked) => {
        const updatedLabels = checked
            ? [...selectedLabels, label]
            : selectedLabels.filter((item) => item !== label);
        form.setFieldsValue({
            property_type: updatedLabels,
        });
        setSelectedLabels(updatedLabels);

        const values = form.getFieldsValue();
        onFinish(values);
    };
    const handlePriceChange = (mergedValues) => {

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const timeoutId = setTimeout(() => {
            onFinish(mergedValues, "formValues");
        }, 400);
        setTypingTimeout(timeoutId);
    };
    return (
        <div className="customDropdown">
            <h3 className="dropdownHeading">Property Type</h3>
            <Form
                form={form}
                name="moreFilter"
                layout="vertical"

                onValuesChange={(changedValues, allValues) => {
                    if (changedValues.hasOwnProperty("square_feet") || changedValues.hasOwnProperty("year_built")) {
                        const mergedValues = { ...allValues, ...changedValues };
                        handlePriceChange(mergedValues);

                    } else {
                        onFinish(allValues, "formValues");
                    }
                }}
            >
                <div className="row">
                    <div className="col-md-4">
                        <div className="card dropDownCard">
                            <div className="card-body">
                                <Form.Item name="property_type">

                                    {propertyTypes.map((type) => (
                                        <Row key={type.value} style={{ marginBottom: "10px" }}>
                                            <Checkbox
                                                checked={selectedLabels.includes(type.label)}
                                                onChange={(e) =>
                                                    handleCheckboxChange(type.label, e.target.checked)
                                                }
                                            >
                                                {type.label}
                                            </Checkbox>
                                        </Row>
                                    ))}

                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="card dropDownCard">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item
                                            label="Min Square Feet"
                                            className="form-control-check"
                                            name="square_feet"
                                            rules={[
                                                {
                                                    pattern: /^[0-9]+$/,
                                                    message: "Please enter a valid number",
                                                }]}
                                        >
                                            <Input
                                                className="filter-control"
                                                placeholder="Square Feet"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="SqFt. Price"
                                            className="form-control-check"

                                        >
                                            <Select placeholder="Select range" disabled>
                                                <Option value="any">Any</Option>
                                                <Option value="500-1000">500 - 1000</Option>
                                                <Option value="1000-2000">1000 - 2000</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            label="Year Built"
                                            className="form-control-check"
                                            name="year_built"
                                            rules={[
                                                {
                                                    pattern: /^(19|20)\d{2}$/,
                                                    message: "Please enter a valid year ",
                                                },]}
                                        >
                                            <Input
                                                className="filter-control"
                                                placeholder="Year Built"
                                            />
                                        </Form.Item>

                                        <Form.Item label="Lot Size">
                                            <Select
                                                placeholder="Select size"
                                                className="form-control-check"
                                                disabled
                                            >
                                                <Option value="any">Any</Option>
                                                <Option value="small">Small</Option>
                                                <Option value="medium">Medium</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

const Filters = ({ onFilter, propertyCardData, searchLoader }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const state = useSelector((state) => state?.persistedReducer?.userDetail?.userDetail)
    const [form] = Form.useForm();
    const [filterProperty, { isLoading: filterLoading }] =
        useSearchPropertyMutation();
    const { data: getAddress } = useGetLocationQuery(location?.state?.nearMe);
    const[recentSearchZip] = useRecentlyZipCodeMutation()
    const [data, setData] = useState();
    const [filter, setFilter] = useState({});
    const [sorting, setSorting] = useState({});
    const [count, setCount] = useState();
    const [loading, setLoading] = useState(true);
    const [zipCode, setZipCode] = useState()
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [isZipCodeSearch, setIsZipCodeSearch] = useState(false);
    const [addressDetails, setAddressDetails] = useState({
        zipCode: "",
        city: "",
        state: "",
        country: "",
    });
    const [messageApi, contextHolder] = message.useMessage();

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };



    useEffect(() => {
        if (location?.state?.zip_code) {
            form.setFieldsValue(location?.state?.filterValue)
            setData(location?.state);
            setLoading(false);
        } else {

            setData(getAddress);
            setLoading(false);
        }
    }, [location?.state?.zip_code, getAddress]);



    useEffect(() => {
        if (propertyCardData) {
            setLoading(false);
            setCount(propertyCardData);
        } else {
            setCount(0)
        }
    }, [propertyCardData]);

    useEffect(() => {
        if (zipCode) {
            dispatch(saveZipCode({ zip: zipCode }))
        } else if (location?.state?.nearMe) {
            dispatch(saveZipCode({ zip: getAddress?.zip_code }))
        } else if (location?.state?.zip) {
            dispatch(saveZipCode({ zip: state.zips[0]?.zip_code }))
        } else if (location?.state?.zip_code) {
            dispatch(saveZipCode({ zip: location?.state?.zip_code }))
        }
    }, [location?.state?.zip_code, location?.state?.nearMe, location?.state?.zip, zipCode])


    const onChangeSort = (value) => {
        let sort_order = "desc";
        let sort_criteria = "";
        switch (value.value) {
            case "3":
                sort_order = "asc";
                break;
            case "4":
                sort_order = "desc";
                break;
            case "5":
                sort_order = "asc";
                break;
            case "6":
                sort_order = "desc";
                break;
            default:
                sort_order = "asc";
        }
        switch (value.value) {
            case "1":
                sort_criteria = "bedrooms_total";
                break;
            case "2":
                sort_criteria = "bathrooms_total";
                break;
            case "3":
                sort_criteria = "price";
                break;
            case "4":
                sort_criteria = "price";
                break;
            case "5":
                sort_criteria = "lot_size";
                break;
            case "6":
                sort_criteria = "lot_size";
                break;
            case "7":
                sort_criteria = "year_built";
                break;
            default:
                sort_criteria = "";
        }
        let data = {
            sort_by: sort_criteria,
            sort_order: sort_order,
        };
        setSorting(data);
    };

    const onSearchSort = (value) => {
        console.log("search:", value);
    };
    const onFinish = async (value) => {

        let values = {};
        if (zipCode) {

            values = {
                zip_code: zipCode,
                ...value,
                ...filter,
                ...sorting,
            };
        } else {

            if (location?.state?.zip_code || location?.state?.searchTerm) {
                values = {
                    zip_code: location?.state?.zip_code || location?.state?.searchTerm,
                    ...value,
                    ...filter,
                    ...sorting,
                };
            } else if (location?.state?.nearMe) {
                values = {
                    nearme: true,
                    current_page: location?.state?.currentPage,
                    ...value,
                    ...filter,
                    ...sorting,
                };
            } else if (location?.state?.zip) {
                values = {
                    myzip: true,
                    current_page: location?.state?.currentPage,
                    ...value,
                    ...filter,
                    ...sorting,
                };
            }
        }


        try {
            const res = await filterProperty(values);
            if (res?.data) {

                setCount(res?.data?.meta?.total);
                onFilter({ data: res.data, filterLoading });

            } else {
                setCount(0);
                onFilter({ data: { data: [] }, filterLoading });
            }
        } catch (error) {
            console.error("Error filtering properties:", error);
        }
    };
    const handlePriceChange = (mergedValues) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const timeoutId = setTimeout(() => {
            onFinish(mergedValues, "formValues");
        }, 400);

        setTypingTimeout(timeoutId);
    };
    const recentSearch = async() =>{
        if(zipCode){
            try {
                const res = await recentSearchZip({search_term:zipCode})
    
            } catch (error) {
                console.error("Error filtering properties:", error);
            }
        }
      
    }
    useEffect(() => {
        if (Object.keys(filter).length > 0 || Object.keys(sorting).length > 0) {
            const formValues = form.getFieldsValue();
            onFinish(formValues);
        } else if (zipCode?.length > 0 || zipCode?.length === 0) {
            const formValues = form.getFieldsValue();
            onFinish(formValues);
        }
        recentSearch()
    }, [filter, sorting, zipCode]);
    useEffect(()=>{
 onFinish()
    },[location?.state?.searchTerm])
    const handleZipCodeSearch = async (zipCode) => {

        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${google_Api_Key}`
            );

            if (response.data.results.length > 0) {
                const addressComponents = response.data.results[0].address_components;
                let searchCity = "", searchState = "", searchCountry = "";
    
                addressComponents.forEach((component) => {
                    const types = component.types;
                    if (types.includes("locality") || types.includes("postal_town")) {
                        searchCity = component.long_name;
                    }
                    if (types.includes("administrative_area_level_1")) {
                        searchState = component.long_name;
                    }
                    if (types.includes("country")) {
                        searchCountry = component.long_name;
                    }
                });
    
                setAddressDetails({
                    zipCode: zipCode,
                    city: searchCity,
                    state: searchState,
                    country: searchCountry
                });
    
                form.setFieldsValue({
                    zip_code: zipCode,
                    city: searchCity,
                    state: searchState,
                    country: searchCountry
                });
    
               // Set flag after successful ZIP code search
            } else {
                errorMessage("Invalid ZIP code, please try again.");
            }
        } catch (error) {

            errorMessage("Unable to fetch location details for the ZIP code.");
        }
    };

  
    return (
        <div className="container">
            {contextHolder}
            <div className="row">
                <div className="col-lg-12">
                    <div className="filterRow">
                        <Form
                            form={form}
                            name="searchForm"
                            layout="vertical"
                            onValuesChange={(changedValues, allValues) => {
                                if (changedValues.hasOwnProperty("min_price") || changedValues.hasOwnProperty("max_price")) {
                                    const mergedValues = { ...allValues, ...changedValues };
                                    handlePriceChange(mergedValues);

                                } else {
                                    onFinish(allValues, "formValues");
                                }
                            }}
                            autoComplete="off"
                        >
                            <Form.Item >
                                <Autocomplete
                                    className="filter-control"
                                    apiKey={google_Api_Key}
                                    defaultValue={location?.state?.address || location?.state?.searchTerm}
                                    placeholder="Enter ZIP or location here"
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (!inputValue) {
                                            setZipCode("");
                                            setAddressDetails({
                                                zipCode: "",
                                                city: "",
                                                state: "",
                                                country: ""
                                            });
                                            form.setFieldsValue({ zip_code: "" });
                                            setIsZipCodeSearch(false); // Reset flag
                                        } else if (/^\d{5}$/.test(inputValue)) {
                                           setIsZipCodeSearch(true);
                                            setZipCode(inputValue);
                                            form.setFieldsValue({ zip_code: inputValue });
                                       
                                            handleZipCodeSearch(inputValue); // Call the function to handle ZIP code search
                                        }
                                    }}
                                    onPlaceSelected={(place) => {
                                        const addressComponents = place.address_components;
                                        let searchzipCode = "";
                                        let searchState = "";
                                        let searchCity = "";
                                        let searchCountry = "";
                                      
                                    if(!isZipCodeSearch)
                                    {
                                         
                                        addressComponents?.forEach((component) => {
                                            const types = component.types;
                                            if (types.includes("postal_code")) {
                                                searchzipCode = component.long_name;
                                                setZipCode(searchzipCode);
                                            }
                                            if (types.includes("administrative_area_level_1")) {
                                                searchState = component.long_name;
                                            }
                                            if (types.includes("locality") || types.includes("postal_town")) {
                                                searchCity = component.long_name;
                                            }
                                            if (types.includes("country")) {
                                                searchCountry = component.long_name;
                                            }
                                        });
                                    
                                        form.setFieldsValue({ zip_code: searchzipCode });
                                        if (searchzipCode || searchCity || searchState || searchCountry ) {
                                            setAddressDetails({
                                                zipCode: searchzipCode,
                                                city: searchCity,
                                                state: searchState,
                                                country: searchCountry
                                            });
                                        } else {
                                            errorMessage("Please enter a valid location.");
                                        }
                                    }
                                       
                                    }}
                                    style={{ width: "100%" }}
                                    options={{
                                        types: ["geocode"],
                                        componentRestrictions: { country: "US" },
                                    }}
                                />
                            </Form.Item>

                            <Form.Item name="beds">
                                <Select
                                    showSearch
                                    placeholder="All Beds"
                                    optionFilterProp="label"
                                    className="filter-select"
                                    options={[
                                        { value: "", label: "All Beds" },
                                        { value: "1+", label: "1+ Beds" },
                                        { value: "2+", label: "2+ Beds" },
                                        { value: "3+", label: "3+ Beds" },
                                        { value: "4+", label: "4+ Beds" },
                                        { value: "5+", label: "5+ Beds" },
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item name="baths">
                                <Select
                                    showSearch
                                    placeholder="All Baths"
                                    optionFilterProp="label"
                                    className="filter-select"
                                    options={[
                                        { value: "", label: "All Bath" },
                                        { value: "1+", label: "1+ Baths" },
                                        { value: "2+", label: "2+ Baths" },
                                        { value: "3+", label: "3+ Baths" },
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item className="priceWrap" name="min_price" rules={[
                                {
                                    pattern: /^[0-9]+$/,
                                    message: "Please enter a valid number",
                                }]}>
                                <Input className="filter-control" placeholder="Min Price" />
                            </Form.Item>

                            <Form.Item className="priceWrap" name="max_price" rules={[
                                {
                                    pattern: /^[0-9]+$/,
                                    message: "Please enter a valid number",
                                }]}>
                                <Input className="filter-control" placeholder="Max Price" />
                            </Form.Item>

                            <Form.Item className="moreFilter">
                                <Dropdown
                                    dropdownRender={() => (
                                        <DropdownContent
                                            count={count}
                                            setCount={setCount}
                                            onFilter={onFilter}
                                            setFilter={setFilter}
                                        />
                                    )}
                                    trigger={["click"]}
                                >
                                    <Space>
                                        More Filters
                                        <DownOutlined />
                                    </Space>
                                </Dropdown>
                            </Form.Item>

                            {/* Optionally, you can add the search button back */}
                            {/* <button type="submit" className="btn accountBtn">Search</button> */}
                        </Form>
                    </div>
                    <div className="sortingRow">
                        <div className="leftSort">
                            <h4 className="sortingText">
                                {filterLoading || searchLoader ? (
                                    <span className="loadingText">Sit tight! We are searching the properties for you...</span>
                                ) : count === 0 ? (
                                    `No Listings were found in your location`
                                ) : addressDetails?.city || addressDetails?.country ? (
                                    `We found ${count} Listings in ${[
                                        addressDetails?.city ?? '',
                                        addressDetails?.country ?? ''
                                    ].filter(Boolean).join(', ')}`
                                ) : data?.city || data?.country ? (
                                    `We found ${count} Listings in ${[
                                        data?.city ?? '',
                                        data?.country ?? ''
                                    ].filter(Boolean).join(', ')}`
                                ) : location?.state?.zipCode || location?.state?.searchTerm && !zipCode ? (
                                    `Find the perfect property using your preferred ZIP code`
                                ) : (
                                    `No Listings were found in your location`
                                )}
                            </h4>
                            {location?.state?.zipCode ?
                                <div className="zipResult">
                                    {location?.state?.zipCode.map((item) => {
                                        return (
                                            <span className="pills">{item.zip_code}</span>
                                        )
                                    })}
                                </div> : ''}
                        </div>


                        <div className="sortWrap">
                            <Form initialValues={{
                                sort_by: "Sort",
                            }}>
                                <Form.Item className="moreFilter" label="Sort by:" name="sort_by">
                                    <Select
                                        showSearch
                                        optionFilterProp="label"
                                        onChange={(value) => onChangeSort(value)}
                                        labelInValue
                                        onSearch={onSearchSort}
                                        className="filter-sort"
                                        options={[
                                            {
                                                value: "1",
                                                label: "Beds",
                                            },
                                            {
                                                value: "2",
                                                label: "Baths",
                                            },
                                            {
                                                value: "3",
                                                label: "Price (low to high)",
                                            },
                                            {
                                                value: "4",
                                                label: "Price (high to low)",
                                            },
                                            {
                                                value: "5",
                                                label: "Square Feet (low to high)",
                                            },
                                            {
                                                value: "6",
                                                label: "Square Feet (high to low)",
                                            },
                                            {
                                                value: "7",
                                                label: "Year Built (newest to oldest)",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filters;
