import React, { useEffect, useState } from "react";
import {
    SliderImageOne
} from "../../constant/images";
import { TbBed, TbVector } from "react-icons/tb";
import { LuBath } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { useGetRecentlyPropertyQuery } from "../../store/getSlice";
import { Empty, Spin, Button } from "antd";
import propertyImg1 from '../../assets/images/propertyImg_1.png';
import propertyImg2 from '../../assets/images/propertyImg_2.png';
import propertyImg4 from '../../assets/images/propertyImg_4.png';
import propertyImg5 from '../../assets/images/propertyImg_5.png';
import propertyImg6 from '../../assets/images/propertyImg_6.png';

const RecentSection = () => {
    const { data: getRecentlyProperty, isLoading, refetch } = useGetRecentlyPropertyQuery();
    const [showMore, setShowMore] = useState(false);

    const propertyImages = [
        propertyImg1,
        propertyImg2,
        propertyImg4,
        propertyImg5,
        propertyImg6,
    ];

    useEffect(() => {
        refetch()
    }, [])

    // Function to handle the toggle of showing more or fewer properties
    const handleShowMoreToggle = () => {
        setShowMore((prevShowMore) => !prevShowMore);
    };

    // Conditionally slice the property data to show only the top 3 initially
    const displayedProperties = showMore
        ? getRecentlyProperty?.data
        : getRecentlyProperty?.data?.slice(0, 3);

    return (
        <div className="RecentWrapper">
            {getRecentlyProperty?.data?.length ? (
                <div className="card recentCard">
                    <div className="card-header">
                        <h4 className="card-title">
                            Recently Viewed
                        </h4>
                    </div>
                    <div className="card-body">
                        {isLoading ? (
                            <Spin
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                size="large"
                            />
                        ) : getRecentlyProperty?.data?.length ? (
                            displayedProperties.map((item, index) => {
                                const imageSrc = propertyImages[index] || SliderImageOne;
                                return (
                                    <div className="recentProperty" key={item.property_id}>
                                        <div className="propertyImage">
                                            <img src={imageSrc} className="img-fluid" alt="property" />
                                        </div>
                                        <div className="propertyDetails">
                                            <h4 className="name">{item?.property.unparsed_address}</h4>
                                            <p className="location"><FaLocationDot /> {`${item?.property.postal_city}, ${item?.property.state_or_province}, ${item?.property.country}`}</p>
                                            <div className="aminities">
                                                <span className="beds"><TbBed /> {item?.property?.bedrooms_total} Beds</span>
                                                <span className="bath"><LuBath /> {item?.property?.bathrooms_total} Baths</span>
                                                <span className="bath"><TbVector /> {item?.property?.lot_size} Sqft</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="emptyContainer">
                                <Empty />
                            </div>
                        )}
                    </div>

                    {/* Show More/Show Less Button */}
                    {getRecentlyProperty?.data?.length > 3 && (
                        <div className="showMoreButton">
                            <Button onClick={handleShowMoreToggle}>
                                {showMore ? "Show Less" : "Show More"}
                            </Button>
                        </div>
                    )}
                </div>
            ) : ''}
        </div>
    );
}

export default RecentSection;