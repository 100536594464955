import React from "react";

import { Empty, Spin } from "antd";
import { useGetPagesDetailQuery } from "../../store/loginRegisterSlice";
import { useSelector } from "react-redux";

const About = () => {
    const { data: getAboutUs, isLoading } = useGetPagesDetailQuery("about-us");
    const state = useSelector((state) => state?.persistedReducer?.banner);
    return (
        <>
            {isLoading ? (
                <div className="spinContainer">
                    <Spin size="large" />
                </div>
            ) : getAboutUs?.page && getAboutUs?.page?.status == 1 ? (
                <div className="termsWrapper">
                    <div
                        className="pageHeader"
                        style={{
                            backgroundImage: state?.about
                                ? `url(https://rainfrog-api.aleaspeaks.com/storage/${state?.about})`
                                : `url('../../assets/images/contactUs.jpg')`,
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pageHeading">
                                        <h3>About Us</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card termsCard">
                                        <div className="card-header">
                                            <h4 className="card-title">{getAboutUs?.page?.title}</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="textSection">
                                                <p className="title text-capitalize">
                                                    {" "}
                                                    {getAboutUs?.page?.keywords}:
                                                </p>
                                                <p className="text">{getAboutUs?.page?.summary}</p>

                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: getAboutUs?.page?.description,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="emptyContainer">
                    <Empty />
                </div>
            )}
        </>
    );
};

export default About;
