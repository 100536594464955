import React, { useEffect, useState, useRef } from "react";
import { Logo, BadgeGold } from "../../constant/images";
import { Button, Form, Input, message, Spin, DatePicker } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useGetBannerQuery, useGetSettingQuery, useSunbirdUserRegisterMutation } from "../../store/loginRegisterSlice";
import { useGetRegisterUserDetailsQuery } from "../../store/getSlice";
import { useDispatch } from "react-redux";
import { allBanner, dynamicWebData, loginSuccess } from "../../store/authSlice";
import moment from 'moment';
import Footer from "../../components/footer/unAuthFooter";
import { IoArrowBack } from "react-icons/io5";

const OnBoarding = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [cardForm] = Form.useForm()
    const [showForm, setShowForm] = useState(true);
    const [isSaving, setIsSaving] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [userDetails, setUserDetail] = useState();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    const [dynamicData, setDynamicData] = useState()
    const { data: getSetting } = useGetSettingQuery()
    const { data: getBanner } = useGetBannerQuery()
    const [sunbirdUserRegister] = useSunbirdUserRegisterMutation()
    const datePickerRef = useRef(null);
    const { data: getRegisterUserDetails, isLoading: isUserDataLoading } =
        useGetRegisterUserDetailsQuery(token);
    const amount = getRegisterUserDetails?.data?.amount;

    const formattedAmount = amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const onSubmit = (values) => {
        setUserDetail(values)
        setShowForm(false);
    };

    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };

    const handleInputClick = () => {
        datePickerRef.current?.focus();
    };

    const onFinish = async (values) => {
        setIsSaving(true)
        const date = new Date(values.exp_date);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        const formattedDate = `${month}/${year}`;

        let data = {
            name_on_card: values.name_on_card,
            credit_card_number: values.credit_card_number,
            exp_date: formattedDate,
            cvv: values.cvv,
            terms_and_conditions: false,
            ...userDetails,
        }

        try {
            const res = await sunbirdUserRegister({ data: data, id: getRegisterUserDetails?.data?.id });
            if (res.data) {
                form.resetFields()
                cardForm.resetFields()
                success(res.data.message);
                localStorage.setItem("Token", res.data.token)
                dispatch(loginSuccess({ user: res.data }))
                navigate("/homepage");
            } else {

                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        } finally {
            setIsSaving(false)
        }
    };

    useEffect(() => {
        if (getRegisterUserDetails?.data) {
            const formValues = getRegisterUserDetails?.data
            form.setFieldsValue(formValues);
        } else {
            form.resetFields();
        }
    }, [getRegisterUserDetails, form]);

    const validateExpiryDate = (_, value) => {
        if (!value) {
            return Promise.reject('Please enter the expiry date');
        }

        const date = new Date(value);
        const currentDate = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();

        if (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month < currentDate.getMonth())) {
            return Promise.reject('The expiry date cannot be in the past');
        }

        return Promise.resolve();
    };

    useEffect(() => {
        setDynamicData(getSetting?.setting)
        dispatch(dynamicWebData(getSetting?.setting))
        dispatch(allBanner(getBanner?.banner))
    }, [getSetting, getBanner])

    const cardSubmit = () => {

        if (userDetails) {
            cardForm.resetFields()

        }
        const newCardData = { ...getRegisterUserDetails?.data?.cards[0] };

        if (newCardData.exp_date) {
            const [month, year] = newCardData.exp_date.split('/');
            const formattedExpDate = `20${year}-${month.padStart(2, '0')}`;
            newCardData.exp_date = formattedExpDate;
        }

        cardForm.setFieldsValue(newCardData);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const goBack = () => {
        setShowForm(true);
    }

    return (
        <>
            {isUserDataLoading ? (
                <div className="spinContainer">
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <div className="authWrapper onBoardWrapper">
                        {contextHolder}
                        <div className="innerWrapper">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card authCard overflow-hidden">
                                            <div className="card-body p-0">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 py-4">
                                                        <div className="formWrapper">
                                                            <div className="logoSection">
                                                                <img src={dynamicData?.logo ? `https://rainfrog-api.aleaspeaks.com/storage/${dynamicData?.logo}` : Logo} className="img-fluid" alt="logo" />
                                                            </div>
                                                            <SwitchTransition>
                                                                <CSSTransition
                                                                    key={showForm ? "signUpForm" : "newPasswordForm"}
                                                                    timeout={300}
                                                                    classNames="slide"
                                                                >
                                                                    {showForm ? (
                                                                        <div className="formSection signUpForm">
                                                                            <h4 className="authTitle">Sign Up</h4>
                                                                            <p className="subTitle">Welcome! Please enter your details.</p>
                                                                            <div className="fomrWrap">
                                                                                <Form
                                                                                    form={form}
                                                                                    name="signUpForm"
                                                                                    layout="vertical"
                                                                                    onFinish={onSubmit}
                                                                                    onFinishFailed={onFinishFailed}
                                                                                    autoComplete="off"
                                                                                >
                                                                                    <Form.Item
                                                                                        label="Name"
                                                                                        name="name"
                                                                                    >
                                                                                        <Input
                                                                                            className="form-control"
                                                                                            placeholder="Name"
                                                                                            autoComplete="off"
                                                                                            disabled
                                                                                        />
                                                                                    </Form.Item>

                                                                                    <Form.Item
                                                                                        label="Email"
                                                                                        name="email"
                                                                                    >
                                                                                        <Input
                                                                                            className="form-control"
                                                                                            placeholder="Enter your email"
                                                                                            autoComplete="off"
                                                                                            disabled
                                                                                        />
                                                                                    </Form.Item>

                                                                                    <Form.Item
                                                                                        label="Password"
                                                                                        name="password"
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message:
                                                                                                    "Please input your password!",
                                                                                            },
                                                                                            {
                                                                                                pattern:
                                                                                                    /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                                                                message:
                                                                                                    "Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input.Password
                                                                                            className="form-control"
                                                                                            placeholder="New Password"
                                                                                        />
                                                                                    </Form.Item>

                                                                                    <Form.Item
                                                                                        label="Confirm Password"
                                                                                        name="password_confirmation"
                                                                                        dependencies={["password"]}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message:
                                                                                                    "Please input your Confirm password!",
                                                                                            },
                                                                                            ({ getFieldValue }) => ({
                                                                                                validator(rule, value) {
                                                                                                    if (
                                                                                                        !value ||
                                                                                                        getFieldValue("password") ===
                                                                                                        value
                                                                                                    ) {
                                                                                                        return Promise.resolve();
                                                                                                    }
                                                                                                    return Promise.reject(
                                                                                                        "The passwords that you entered do not match!"
                                                                                                    );
                                                                                                },
                                                                                            }),
                                                                                        ]}
                                                                                    >
                                                                                        <Input.Password
                                                                                            className="form-control"
                                                                                            placeholder="Confirm Password"
                                                                                        />
                                                                                    </Form.Item>

                                                                                    <Form.Item>
                                                                                        <Button type="primary" htmlType="submit" className="primaryButton" onClick={() => cardSubmit()}>
                                                                                            Continue
                                                                                        </Button>
                                                                                    </Form.Item>
                                                                                </Form>
                                                                            </div>
                                                                            <div className="bottomText">
                                                                                <p>
                                                                                    Need more help? <Link to={'/contact-us'} className="linkText">Contact Us</Link>.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="formSection newPasswordForm">
                                                                            <h4 className="authTitle">Payment Form</h4>
                                                                            <p className="subTitle">Unlock a New Level of Property Insights: See What We Offer!</p>
                                                                            <div className="fomrWrap">
                                                                                <Form
                                                                                    name="paymentModal"
                                                                                    layout="vertical"
                                                                                    onFinish={onFinish}
                                                                                    form={cardForm}
                                                                                >
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <Form.Item name="name_on_card" label="Cardholder’s Name"
                                                                                                rules={[
                                                                                                    { required: true, message: 'Please enter the cardholder’s name' },
                                                                                                    {
                                                                                                        pattern: /^[A-Za-z\s]+$/,
                                                                                                        message: 'Cardholder’s name can only contain letters and spaces',
                                                                                                    },
                                                                                                    {
                                                                                                        max: 25,
                                                                                                        message: 'Cardholder’s name cannot exceed 25 characters',
                                                                                                    },
                                                                                                ]}>
                                                                                                <Input className="form-control" placeholder="John Doe" autoComplete="off" />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <Form.Item name="credit_card_number" label="Card Number"
                                                                                                rules={[
                                                                                                    { required: true, message: 'Please enter your card number' },
                                                                                                    { pattern: /^[0-9]{16}$/, message: 'Card number must be 16 digits' },
                                                                                                ]}>
                                                                                                <Input
                                                                                                    className="form-control"
                                                                                                    placeholder="***888***9999765"
                                                                                                    maxLength={16}
                                                                                                    inputMode="numeric"
                                                                                                    onKeyPress={(e) => {
                                                                                                        if (!/[0-9]/.test(e.key)) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />

                                                                                            </Form.Item>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <Form.Item name="exp_date" label="Expiry Date"
                                                                                                rules={[{ validator: validateExpiryDate }]}>
                                                                                                <DatePicker
                                                                                                    ref={datePickerRef}
                                                                                                    picker="month"
                                                                                                    format="MM/YY"
                                                                                                    autoComplete="off"
                                                                                                    placeholder="MM/YY"
                                                                                                    inputReadOnly
                                                                                                    className="form-control"
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <Form.Item name="cvv" label="CVV"
                                                                                                rules={[
                                                                                                    { required: true, message: 'Please enter the CVV' },
                                                                                                    { pattern: /^[0-9]{3}$/, message: 'CVV must be 3  digits' },
                                                                                                ]}>
                                                                                                <Input.Password className="form-control" placeholder="CVV" maxLength={3} autoComplete="off" />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <Button type="submit" className="primaryButton text-white" disabled={isSaving} htmlType="submit">Proceed to Pay</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Form>
                                                                            </div>
                                                                            <div className="bottomText d-flex align-items-center justify-content-between">
                                                                                <button type="button" className="btn backButton" onClick={() => goBack()}>
                                                                                    <IoArrowBack /> Back
                                                                                </button>
                                                                                <p>
                                                                                    Need more help? <Link to={'/contact-us'} className="linkText">Contact Us</Link>.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </CSSTransition>
                                                            </SwitchTransition>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 trialSection">
                                                        <div className="rightSection">

                                                            <div className="innerSec">
                                                                <div className="trialDraw">
                                                                    <div class="daysSection">
                                                                        <div className="triangleText">{getRegisterUserDetails?.data?.trial_period ? getRegisterUserDetails?.data?.trial_period === 1 ? `${getRegisterUserDetails?.data?.trial_period} day` : `${getRegisterUserDetails?.data?.trial_period} days` : `0 day`}</div>
                                                                    </div>
                                                                    <div className="mainDraw">
                                                                        <div className="badgeSection">
                                                                            <img src={BadgeGold} className="img-fluid" alt="plan" />
                                                                        </div>
                                                                        <div className="innerDraw">
                                                                            <h2 className="freeText">Free</h2>

                                                                            <div className="benifits">
                                                                                <ul>
                                                                                    <li><p>Search over 1M active property listings</p></li>
                                                                                    <li><p>Properties updated daily</p></li>
                                                                                    <li><p>Save custom searches</p></li>
                                                                                    <li><p>24/7 access</p></li>
                                                                                    <li><p>Cancel anytime</p></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="trialTextDraw">
                                                                        <h2 className="trailText">Trial</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="benifitsSection">
                                                                    <p className="noteRow">
                                                                        {getRegisterUserDetails?.data?.amount && getRegisterUserDetails?.data?.billing_period && getRegisterUserDetails?.data?.next_payment_date ? (
                                                                            <>
                                                                                <span className="asterisk">*</span> You will be charged ${formattedAmount}  {getRegisterUserDetails?.data?.billing_period === 'monthly'
                                                                                    ? 'every Month'
                                                                                    : getRegisterUserDetails?.data?.billing_period === 'weekly'
                                                                                        ? 'every Week'
                                                                                        : getRegisterUserDetails?.data?.billing_period === 'biweekly'
                                                                                            ? 'twice a Month'
                                                                                            : ''} starting from {moment(getRegisterUserDetails?.data?.next_payment_date).format('MMM DD, YYYY')}.
                                                                            </>
                                                                        ) : <>&nbsp;</>}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            )}
        </>

    );
};

export default OnBoarding;