import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    user: null,
    email:null,
    userDetail:null,
    accessToken:null,
    zip:null,
    webData:null,
    address:null,
    banner:null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
        },
        saveEmail: (state, action) => {
            state.email = action.payload;
          },
          userData: (state, action) => {
            state.userDetail = action.payload;
          },
          tokenUpdated(state, action) {
            state.user.accessToken = action.payload.accessToken;
        },

          saveZipCode: (state, action) => {
            state.zip = action.payload;
          },

          dynamicWebData:(state, action) => {
            state.webData = action.payload;
          },
          currentAddress:(state, action) => {
            state.address = action.payload;
          },
          allBanner:(state,action) =>{
           state.banner = action.payload
          },

          logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
          },

    },
});

 export const { loginSuccess,saveEmail,logout,userData,saveZipCode,tokenUpdated,dynamicWebData,currentAddress,allBanner } = authSlice.actions;

export default authSlice.reducer;
