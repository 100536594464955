import React, { useEffect, useState } from "react";
import { Avatar, message, Input, Form, Select, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { google_Api_Key } from "../../Helper";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { useDispatch, useSelector } from "react-redux";
import { saveZipCode, userData } from "../../store/authSlice";
import swal from 'sweetalert';
import { useRecentlyZipCodeMutation } from "../../store/apiSlice";

const BannerSection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const state = useSelector((state)=>state?.persistedReducer?.banner)
    const [form] = Form.useForm()
    const { data: getUserDetail } = useGetUserDetailQuery()
    const[recentSearchZip] = useRecentlyZipCodeMutation()
    const [messageApi, contextHolder] = message.useMessage();
    const [formattedAddress, setFormatedAddress] = useState()
    const [failStatus, setFailStatus] = useState()
    const [saving, setSaving] = useState(false)
    const [zipSubmit, setzipSubmit] = useState(false)
    const [addressDetails, setAddressDetails] = useState({
        zipCode: "",
        city: "",
        state: "",
        country: "",
    });
    const currentDate = new Date();
    const nextPaymentDate = new Date(getUserDetail?.data?.next_payment_date);
    const isFormEmpty = Object.values(addressDetails).every(value => !value);
    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };
    useEffect(() => {
        dispatch(userData({ userDetail: getUserDetail?.data }))
        if (getUserDetail?.data?.status === 'trail_expired' || getUserDetail?.data?.status === 'inactive' || currentDate > nextPaymentDate) {
            setFailStatus(getUserDetail?.data?.status)
        } else {
            setFailStatus('')
        }
        dispatch(saveZipCode({ zip: null}))
    }, [getUserDetail])
    const recentSearch = async() =>{
        if(addressDetails.zipCode){
            try {
                const res = await recentSearchZip({search_term:addressDetails.zipCode})
    
            } catch (error) {
                console.error("Error filtering properties:", error);
            }
        }
      
    }
    const handleSearch = () => {
        const formValues = form.getFieldsValue();
        recentSearch()
        navigate("/search", {
            state: {
                city: addressDetails.city,
                state: addressDetails.state,
                country: addressDetails.country,
                zip_code: addressDetails.zipCode,
                filterValue: formValues,
                address: formattedAddress,
                currentPage: 1

            },
        });

    }
  

    return (
        <div className="bannerWrapper" style={{
            backgroundImage: state?.home
              ? `url(https://rainfrog-api.aleaspeaks.com/storage/${state?.home})`
              : `url('../../assets/images/banner.png')`,
          }}>
            {failStatus ? <Alert

                message="Please check your payment details or try again later."
                type="error"
                showIcon
                banner={true}
                style={{ width: '100%' }}
            /> : ''}
            <div className="container">
                {contextHolder}
                <div className="innerWrapper">
                    <div className="row justify-content-between">
                        <div className="col-lg-5 col-md-8">
                            <div className="bannerContent">
                                <h5 className="bannerTopTitle">Rainfrog</h5>
                                <h4 className="mainTitle">Find a perfect home you love..!</h4>
                                <p className="bannerSubTitle">
                                    Where Every Property Search Finds Its Perfect Match.
                                </p>
                                <div className="searchWrap">
                                    <Autocomplete
                                        apiKey={google_Api_Key}
                                        className="search-control"
                                        placeholder="Enter ZIP or location here"
                                        onPlaceSelected={(place) => {
                                            const addressComponents = place.address_components;

                                            setFormatedAddress(place.formatted_address)
                                            let searchzipCode = "";
                                            let searchState = "";
                                            let searchCity = "";
                                            let searchCountry = "";

                                            addressComponents.forEach((component) => {
                                                const types = component.types;

                                                if (types.includes("postal_code")) {
                                                    searchzipCode = component.long_name;

                                                }

                                                if (types.includes("administrative_area_level_1")) {
                                                    searchState = component.long_name;

                                                }

                                                if (
                                                    types.includes("locality") ||
                                                    types.includes("postal_town")
                                                ) {
                                                    searchCity = component.long_name;

                                                }

                                                if (types.includes("country")) {
                                                    searchCountry = component.long_name;

                                                }
                                            });
                                            if (searchzipCode) {
                                                setAddressDetails({
                                                    zipCode: searchzipCode,
                                                    city: searchCity,
                                                    state: searchState,
                                                    country: searchCountry
                                                });

                                            } else {
                                                errorMessage("Please enter a valid location.");
                                            }

                                        }}
                                        style={{ width: "100%" }}
                                        options={{
                                            types: ["geocode"],
                                            componentrestrictions: { country: "US" },
                                        }}
                                    />
                                    <span className="divider"></span>
                                    <button type="button" className="btn accountBtn radius-none" disabled={isFormEmpty} onClick={() => {
                                        if (failStatus) {
                                            swal({
                                                title: "Warning",
                                                text: "Inactive membership, contact your Admin for further details",
                                                icon: "warning",
                                                dangerMode: true,
                                            })
                                                .then(ok => {
                                                    if (ok) {
                                                        navigate('/my-account')
                                                    }
                                                });
                                        } else {
                                            handleSearch();
                                        }
                                    }}>Filter</button>
                                </div>
                                <div className="filterWrap">
                                    <Form
                                        name="filterForm"
                                        className="filterForm"
                                        form={form}
                                    >
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <Form.Item name="beds">
                                                    <Select
                                                        showSearch
                                                        placeholder="All Beds"
                                                        optionFilterProp="label"
                                                        className="form-control-check"
                                                        options={[
                                                            { value: "", label: "All Beds" },
                                                            { value: "1+", label: "1+ Beds" },
                                                            { value: "2+", label: "2+ Beds" },
                                                            { value: "3+", label: "3+ Beds" },
                                                            { value: "4+", label: "4+ Beds" },
                                                            { value: "5+", label: "5+ Beds" },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <Form.Item name="baths">
                                                    <Select
                                                        showSearch
                                                        placeholder="All Baths"
                                                        optionFilterProp="label"
                                                        className="form-control-check"
                                                        options={[
                                                            { value: "", label: "All Bath" },
                                                            { value: "1+", label: "1+ Baths" },
                                                            { value: "2+", label: "2+ Baths" },
                                                            { value: "3+", label: "3+ Baths" },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <Form.Item className="priceWrap" name="min_price" rules={[
                                                    {
                                                        pattern: /^[0-9]+$/,
                                                        message: "Please enter a valid number",
                                                    },
                                                ]}>
                                                    <Input className="form-control" placeholder="Min Price" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <Form.Item className="priceWrap" name="max_price" rules={[
                                                    {
                                                        pattern: /^[0-9]+$/,
                                                        message: "Please enter a valid number",
                                                    },
                                                ]}>
                                                    <Input className="form-control" placeholder="Max Price" />
                                                </Form.Item>
                                            </div>
                                        </div>

                                    </Form>
                                </div>
                                <div className="orSection">
                                    <p className="orText">Or</p>
                                </div>
                                <div className='d-flex justify-content-center gap-10'>
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="btn accountBtn"
                                            onClick={() => {
                                                if (failStatus) {
                                                    setSaving(true)
                                                    swal({
                                                        title: "Warning",
                                                        text: "Inactive membership, contact your Admin for further details",
                                                        icon: "warning",
                                                        dangerMode: true,
                                                    })
                                                        .then(ok => {
                                                            if (ok) {
                                                                navigate('/my-account')
                                                            }
                                                        });
                                                } else {
                                                    navigate("/search", {
                                                        state: {
                                                            currentPage: 1,
                                                            nearMe:true

                                                        },
                                                    });
                                                }
                                            }}
                                            disabled={saving}
                                        >
                                            Search Near Me
                                        </button>
                                    </div>
                                    <div className="text-center mx-4">
                                        <button
                                            type="button"
                                            className="btn accountBtn"
                                            onClick={() => {
                                                if (failStatus) {
                                                    setzipSubmit(true)
                                                    swal({
                                                        title: "Warning",
                                                        text: "Inactive membership, contact your Admin for further details",
                                                        icon: "warning",
                                                        dangerMode: true,
                                                    })
                                                        .then(ok => {
                                                            if (ok) {
                                                                navigate('/my-account')
                                                            }
                                                        });
                                                } else {
                                                    navigate("/search", {
                                                        state: {
                                                            zipCode: getUserDetail?.data?.zips,
                                                            currentPage: 1,
                                                            zip: true
                                                        },
                                                    })
                                                }
                                            }}
                                            disabled={zipSubmit}
                                        >
                                            Search With Zip Code
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bottomRow">
                        <div className="col-lg-3 col-md-6">
                            <div className="happyCustomerWrap">
                                <Avatar.Group
                                    size="large"
                                    max={{
                                        count: 4,
                                        style: {
                                            color: "#f56a00",
                                            backgroundColor: "#FFF",
                                            boxShadow: "10px 30px 50px 0px #0000000D",
                                        },
                                    }}
                                >
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/confident-handsome-guy-posing-against-white-wall_176420-32936.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/premium-photo/young-indian-businesswoman-employee-standing-white-background_1254992-196149.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/close-up-toothy-smiling-brunet-man_1187-5800.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/indian-businessman-with-his-white-car_496169-2889.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/beautiful-male-half-length-portrait-isolated-white-studio-background-young-emotional-hindu-man-blue-shirt-facial-expression-human-emotions-advertising-concept-standing-smiling_155003-25250.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                </Avatar.Group>
                                <span className="text">72k+ <br />Happy Customers</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="listingrWrap">
                                <Avatar
                                    className="bannerAvatarVilla"
                                    src="https://img.freepik.com/free-photo/luxury-pool-villa-spectacular-contemporary-design-digital-art-real-estate-home-house-property-ge_1258-150759.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                />
                                <span className="text">
                                    200+ New <br />
                                    Listings Everyday!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerSection;
