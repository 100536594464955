import React from "react";
import { Row, Col, Image } from "antd";
import { FaCamera, FaLocationDot, FaArrowLeft } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { GalleryImage1, GalleryImage2, GalleryImage3, GalleryImage4, GalleryImage5, GalleryImage6 } from "../../constant/images";
import { useNavigate } from 'react-router-dom';

const Gallery = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="galleryWrapper">
            <div className="innerWrapper">
                <Row className="h-100">
                    <Col span={14}>
                        <div className="galleryImage">
                        <Image src={GalleryImage1} className="img-fluid" alt="property" />
                        </div>
                    </Col>
                    <Col span={10}>
                        <Row className="h-100">
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src={GalleryImage2} className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src={GalleryImage3} className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src={GalleryImage4} className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src={GalleryImage5} className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src={GalleryImage6} className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src="https://img.freepik.com/free-photo/3d-electric-car-building_23-2148972401.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid" className="img-fluid" alt="property" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="bottomRow">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="buttonWrap">
                                    <button type="button" className="btn photoBtn" onClick={goBack}>
                                        <FaArrowLeft /> Back
                                    </button>
                                </div>
                            </div>
                            <div className="col">
                                <div className="buttonsWrap">
                                    <button type="button" className="btn iconBtn me-4">
                                        <IoShareSocial />
                                    </button>
                                    <button type="button" className="btn iconBtn">
                                        <FaLocationDot />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;