import React, { useState } from "react";
import {
    Modal,
    Form,
    Input,
    Table,
    message,
    Button,
} from "antd";
import {
    useAddCardDetailsMutation,
    useDeleteCardDetailsMutation,
    useUpDateCardDetailsMutation,
} from "../../store/apiSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";
import swal from 'sweetalert'

const CreditCardInformation = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cardId, setCardId] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const [upDateCardDetails] = useUpDateCardDetailsMutation();
    const [deleteCardDetails] = useDeleteCardDetailsMutation()
    const [addCardDetails] = useAddCardDetailsMutation();
    const { data: userDetail, refetch } = useGetUserDetailQuery();
    const [isSaving, setIsSaving] = useState(false)
    const [isEditSaving, setIsEditSaving] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [formType, setFormType] = useState();
    const pageSize = 5;
    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };
    const encryptedCardNumber = (cardNumber) => {
        if (typeof cardNumber !== 'string' || cardNumber.length < 4) {
            return 'Invalid card number';
        }
        return cardNumber.slice(0, -4).replace(/\d/g, '*') + cardNumber.slice(-4);
    };
    const showModal = (cardData) => {
        setIsModalOpen(true);
        if (cardData) {
            const newCardData = { ...cardData };

            if (newCardData.exp_date) {
                const [month, year] = newCardData.exp_date.split('/');
                const formattedExpDate = `20${year}-${month.padStart(2, '0')}`;
                newCardData.exp_date = formattedExpDate;
            }

            form.setFieldsValue(newCardData);
        } else {
            form.resetFields();
        }
    };


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const validateExpiryDate = (_, value) => {
        if (!value) {
            return Promise.reject('Please enter the expiry date');
        }

        const date = new Date(value);
        const currentDate = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();

        if (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month < currentDate.getMonth())) {
            return Promise.reject('The expiry date cannot be in the past');
        }

        return Promise.resolve();
    };
    const onFinish = async (values) => {

        const date = new Date(values.exp_date);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        const formattedDate = `${month}/${year}`;
        let data = {
            name_on_card: values.name_on_card,
            credit_card_number: values.credit_card_number,
            exp_date: formattedDate,
            cvv: values.cvv
        }
        if (formType === "Add") {
            setIsSaving(true)
            try {
                const res = await addCardDetails(data);
                if (res.data) {
                    success("Address added succesfully");
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message);
                }
            } catch (error) {
                errorMessage("An unexpected error occurred");
            } finally {
                setIsSaving(false)
            }
        } else {
            setIsEditSaving(true)
            try {
                const res = await upDateCardDetails({ data: data, cardId });
                if (res.data) {
                    success("Card Details updated succesfully");
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message);
                }
            } catch (error) {
                errorMessage("An unexpected error occurred");
            } finally {
                setIsEditSaving(false)
            }
        }

    };
    const handleConfirm = async (id) => {
        try {
            const res = await deleteCardDetails(id);
            if (res.data) {
                success(res.data.message);
                refetch();
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: "Full Name",
            render: (data) => `${data?.name_on_card}`,
        },
        {
            title: "Card Number",
            render: (data) => encryptedCardNumber(data?.credit_card_number),
        },
        {
            title: "Expiration Date",
            render: (data) => `${data?.exp_date}`,
        },

        {
            title: "Options",
            render: (data) => (
                <div className="buttonWrap d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn optionBtn me-3"
                        onClick={() => {
                            showModal(data);
                            setCardId(data?.id);
                            setFormType("Edit");
                        }}
                    >
                        Edit
                    </button>
                    <button type="button" className="btn optionBtn" onClick={() => {
                        swal({
                            title: "Warning",
                            text: "Are you sure want to delete the card details?",
                            icon: "warning",
                            dangerMode: true,
                        })
                            .then(ok => {
                                if (ok) {
                                    handleConfirm(data?.id)
                                }
                            })
                    }}>Delete</button>
                </div>
            ),
        },
    ];

    return (
        <div className="billingWrapper">
            <div className="card accountCard mt-4">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Card Information</h4>
                    <button
                        type="button"
                        className="btn editButton"
                        onClick={() => {
                            showModal();
                            setFormType("Add");
                        }}
                    >
                        Add Card
                    </button>
                </div>

                <div className="tableWrapper table-responsive">
                    <Table
                        className="billingWrap"
                        dataSource={userDetail?.data?.cards?.map(item => ({ ...item, key: item.id }))}
                        columns={columns}
                        pagination={{
                            pageSize,
                            current: currentPage,
                            onChange: page => setCurrentPage(page),
                        }}
                    />
                </div>
            </div>
            <Modal
                title={`${formType} Card Details`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="formCard"
                centered
                okText="Update"
                footer={null}
            >
                <div className="modalBody">
                    <Form
                        form={form}
                        name="cardForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item name="name_on_card" label="Full Name" rules={[
                                    { required: true, message: 'Please enter the cardholder’s name' },
                                    {
                                        pattern: /^[A-Za-z\s]+$/,
                                        message: 'Cardholder’s name can only contain letters and spaces',
                                    },
                                    {
                                        max: 25,
                                        message: 'Cardholder’s name cannot exceed 25 characters',
                                    },
                                ]}>
                                    <Input className="form-control" placeholder="Full Name" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item name="credit_card_number" label="Card Number" rules={[
                                    { required: true, message: 'Please enter your card number' },
                                    { pattern: /^[0-9]{16}$/, message: 'Card number must be 16 digits' },
                                ]}>
                                    <Input className="form-control" placeholder="Card Number" maxLength={16} inputMode="numeric" 
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault(); 
                                            }
                                        }} />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item name="exp_date" label="Expiration Date" rules={[{ required: true, message: 'Please enter the expiry date' }, { validator: validateExpiryDate }]}>
                                    <Input className="form-control" placeholder="Expiration Date" type='month' style={{ width: '114%' }} />

                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item name="cvv" label="CVV" rules={[
                                    { required: true, message: 'Please enter the CVV' },
                                    { pattern: /^[0-9]{3}$/, message: 'CVV must be 3  digits' },
                                ]}>
                                    <Input.Password className="form-control" placeholder="CVV" maxLength={3} />

                                </Form.Item>
                            </div>
                            <div className="buttonWrapper">
                                <Button htmlType="submit" className="submitBtn me-4" disabled={formType == 'Add' ? isSaving : isEditSaving}>
                                    Save
                                </Button>
                                <button
                                    type="button"
                                    className="btn cancelBtn"
                                    onClick={handleOk}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>

        </div>
    );
};

export default CreditCardInformation;
