import React, { useState } from "react";
import { Collapse, Table } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { BiTransferAlt } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import { useGetTransactionQuery } from "../../store/getSlice";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";

const { Panel } = Collapse;

const TransactionSummary = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const { data: getTransaction } = useGetTransactionQuery()


    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
        },
        {
            title: 'Date',
            render: (text, record) => (
                <Moment format='MM/DD/YYYY'>{record?.created_at}</Moment>
            ),
        },
        {
            title: 'Amount',
            render: (text, record) => {
                const amount = record?.amount;

                const formattedAmount = amount ? amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }) : 'N/A'; 
        
                return (
                    <span>${formattedAmount}</span> 
                );
            },
        },
        
        {
            title: 'Recurring Interval',
            dataIndex: 'interval',
            key: 'interval',
        },
        {
            title: 'Download Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            render: (text, record) => (
                <NavLink
                    to={`https://rainfrog-api.aleaspeaks.com/storage/invoices/${record?.transaction_id}.pdf`}
                    target="_blank"
                    className="btn downloadBtn"
                >
                    <MdOutlineFileDownload />
                </NavLink>
            ),
        },
    ];

    return (
        <div className="transactionWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sectionHeader">
                            <h3 className="sectionHeading">Transaction Summary</h3>
                        </div>
                        <div className="sectionContent">
                            <Collapse
                                accordion
                                ghost
                                bordered={false}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
                                expandIconPosition='end'
                                className="transactionCollapse"
                                defaultActiveKey={[1]}
                                items={[
                                    {
                                        key: '1',
                                        label: <p className="panelHeader"><BiTransferAlt /> View Transactions</p>,
                                        children: (
                                            <div className="tableWrapper table-responsive">
                                                <Table
                                                    className="transactionTable"
                                                    dataSource={getTransaction?.data?.map(item => ({ ...item, key: item.id }))}
                                                    columns={columns}
                                                    pagination={{
                                                        pageSize,
                                                        current: currentPage,
                                                        onChange: page => setCurrentPage(page),
                                                    }}
                                                />
                                            </div>
                                        ),
                                    },
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionSummary;