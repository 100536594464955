import React, { useEffect } from "react";
import { Image, Input } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Logo } from "../../constant/images";
import { SlLocationPin } from "react-icons/sl";
import { LuPhone } from "react-icons/lu";
import { TbMail } from "react-icons/tb";
import { FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa6";
import { IoArrowForward } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useGetBlogListQuery } from "../../store/getSlice";

const Footer = () => {
    const location = useLocation();
    const state = useSelector((state) => state?.persistedReducer?.webData)

    const { data: getBlogList } = useGetBlogListQuery();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <div className="footerWraper">
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <div className="footerLogo">
                                    <img src={state?.logo ? `https://rainfrog-api.aleaspeaks.com/storage/${state?.logo}` : Logo} className="img-fluid" alt="logo" />
                                </div>
                                <p className="address">
                                    <SlLocationPin />
                                    {state?.address}
                                </p>
                                <p className="phone">
                                    <LuPhone /> {state?.contact_number}
                                </p>
                                <p className="email">
                                    <TbMail />
                                    {state?.email}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle">Quick Links</h4>
                                <div className="footerLinks">
                                    <ul className="footerMenuList">
                                        <li className="footerMenuItem">
                                            <Link to={"/homepage"}>Home</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/about-us"}>About</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/contact-us"}>Contact Us</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle">Blogs</h4>
                                <div className="footerLinks">
                                    <ul className="footerMenuList">
                                        {getBlogList?.data?.slice(0, 5).map((item) => {
                                            return (
                                                <li className="footerMenuItem" key={item?.id}>
                                                    <NavLink to="/blog-details" state={{ data: item?.id }}>
                                                        {item?.title}
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle mb-4">Subscribe to our Newsletter!</h4>
                                <div className="inpurSection">
                                    <Input placeholder="Enter email here" className="newsLetterInput" />
                                    <button type="button" className="btn sendBtn">
                                        <IoArrowForward />
                                    </button>
                                </div>
                                <h4 className="footerColTitle mb-2">Follow Us on</h4>
                                <div className="footerSocial">
                                    <Link to={state?.linkedin_link} target="_blank" rel="noopener noreferrer" className="socialLink">
                                    {state?.linkedin_logo?<Image src={`https://rainfrog-api.aleaspeaks.com/storage/${state?.linkedin_logo}`} className="socialLink" preview={false}/>:<FaLinkedinIn />}
                                    </Link>
                                    <Link to={state?.facebook_link} target="_blank" rel="noopener noreferrer" className="socialLink">
                                    {state?.facebook_logo?<Image src = {`https://rainfrog-api.aleaspeaks.com/storage/${state?.facebook_logo}`} className="socialLink" preview={false} />:<FaFacebookF />}
                                    </Link>
                                    <Link to={state?.instagram_link} target="_blank" rel="noopener noreferrer" className="socialLink">
                                   {state?.instagram_logo?<Image src = {`https://rainfrog-api.aleaspeaks.com/storage/${state?.instagram_logo}`}className="socialLink" preview={false} />:<FaInstagram />} 
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyWriteFooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="copyWrite">
                                ©Copyright – All rights reserved.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="footerMenu">
                                <div className="menuList">
                                    <Link to={"/terms-and-conditions"} className="footerMenuLink">Terms of Service</Link>
                                    <Link to={"/privacy-policy"} className="footerMenuLink">Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
