import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
    SliderImageOne,
    SliderImageTwo,
    SliderImageThree
} from "../../constant/images";
import { TbBed } from "react-icons/tb";
import { LuBath } from "react-icons/lu";
import { useGetFeaturedPropertyQuery } from "../../store/getSlice";
// import { FaFireFlameCurved } from "react-icons/fa6";
// import { IoMdHome } from "react-icons/io";
// import { FaDollarSign } from "react-icons/fa";

const FeaturedSection = ({ className }) => {
    const { data: getFeaturedProperty } = useGetFeaturedPropertyQuery()
    return (
        <>
            {getFeaturedProperty?.data ?
                <div className={"featuredWrapper " + className}>

                    <div className="innerWrapper">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="titleSection">
                                        <h5 className="topTitle">Checkout our new</h5>
                                        <h3 className="mainTitle">Featured Properties</h3>
                                        <p className="subTitle">Showcasing the Best of What We Offer. </p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Carousel
                                        additionalTransfrom={0}
                                        arrows
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className=""
                                        containerClass="container-with-dots"
                                        focusOnSelect={false}
                                        itemClass=""
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        responsive={{
                                            desktop: {
                                                breakpoint: {
                                                    max: 3000,
                                                    min: 1024
                                                },
                                                items: 4,
                                                partialVisibilityGutter: 40
                                            },
                                            mobile: {
                                                breakpoint: {
                                                    max: 464,
                                                    min: 0
                                                },
                                                items: 1,
                                                partialVisibilityGutter: 10
                                            },
                                            tablet: {
                                                breakpoint: {
                                                    max: 1024,
                                                    min: 464
                                                },
                                                items: 2,
                                                partialVisibilityGutter: 30
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        showDots={false}
                                        sliderClass="featuredCarousel"
                                        slidesToSlide={1}
                                    >
                                        {getFeaturedProperty?.data?.map((property, index) => {
                                            return (
                                                <div className="card carouselCard" key={index}>
                                                    <div className="cardImage">
                                                        <img src={SliderImageOne} className="img-fluid" alt="card" />
                                                    </div>
                                                    <div className="contentCard">
                                                        <h4 className="contentCard">
                                                            {property?.price
                                                                ? property.price.toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                })
                                                                : '$0.00'}
                                                        </h4>
                                                        <h4 className="name">{property?.unparsed_address}</h4>
                                                        <p className="details">
                                                            {`${property?.postal_city ?? ''}${property?.postal_city ? ',' : ''} 
                                                      ${property?.state_or_province ?? ''}${property?.state_or_province ? ',' : ''} 
                                                     ${property?.country ?? ''}${property?.postal_code ? '-' : ''} 
                                                     ${property?.postal_code ?? ''}`}
                                                        </p>

                                                        <div className="aminities">
                                                            {property?.bedrooms_total ? <span className="beds"><TbBed />{property?.bedrooms_total}Beds</span> : ''}
                                                            {property?.bathrooms_total ? <span className="bath"><LuBath />{property?.bathrooms_total}Baths</span> : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                : ''
            }
        </>
    );
}

export default FeaturedSection;